<template>
  <div ref="container"
       data-scroll
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import { useRoute } from 'vue-router';

// add global class based on current route
const container = ref();
const route = useRoute();
watch(
  () => route.path,
  async newPath => {
    addRouteClass(newPath);
  }
);

const addRouteClass = (path: string) => {
  const page = path === '/' ? 'homepage' : path.split('/')[1];
  if (container.value) container.value.className = page;
};

watch(
  () => container.value,
  async el => {
    addRouteClass(route.path);
  }
);

addRouteClass(route.path);
</script>
